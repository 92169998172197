import React, { useState, useEffect, useRef, createRef } from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Card, Col } from "react-bootstrap";
import axios from "axios"
import { navigate } from "@reach/router"

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/Home/Banner/Banner.js";
import ContactInfo from "../components/Home/ContactInfo/ContactInfo";
import GridBlock from "../components/Mortgage/Grid-block/Grid-block";
import TilesBlock from "../components/Home/ContactInfo/TileBlock";
import MarketAnalysis from "../components/Home/MarketAnalysis/MarketAnalysis";
import MarketVideo from "../components/Home/MarketVideo/MarketVideo";
import ProjectRating from "../components/Home/ProjectRating/ProjectRating"
import LatestBlogs from "../components/Home/LatestBlogs/LatestBlogs"
import LatestBlogsbyCategory from "../components/StaticOne/FilterBlock/LatestBlogsbyCategory"
import LatestBlogsbyTag from "../components/StaticOne/FilterBlock/LatestBlogsbyTag"
import Reviews from "../components/Home/Reviews/Reviews"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import Insights from "../components/InsightsPeoples/Insights"
import MarketStatistics from "../components/InsightsPeoples/MarketStatistics"
import OurPeoples from "../components/InsightsPeoples/OurPeoples"
import ContentBlock from "../components/Content/Content"
import StepContentBlock from "../components/Content/StepContent";
import AccordionBlock from "../components/AccordionBlock/AccordionBlock"
import ValuationAccordionBlock from "../components/AccordionBlock/ValuationAccordionBlock"
import NewProjects from "../components/NewProjects/NewProjects"
import Jobs from "../components/StaticTwo/JobBlock/JobBlock"
import TableuAccordionBlock from "../components/AccordionBlock/TableuAccordionBlock"
import ProjectTimeline from "../components/ProjectTimeline/ProjectTimeline"
import Videos from "../components/Videos/Videos"
import ContactUs from "../components/ContactUs/ContactUs"
import {getDeviceType} from "../components/common/utils.js"
import ProjectFacts from "../components/PropertyFacts/PropertyFacts"
import PropertyFactsList from "../components/PropertyFacts/PropertyFactsList"
import ProjectFactsMaps from "../components/PropertyFacts/Maps"
import ProjectFactsSlider from "../components/PropertyFacts/ProjectFactsSlider"
import RegisterForm from '../components/forms/banner_register_form';

import MemberCard from "../components/MemberCard/MemberCard"
import FilterBlock from "../components/StaticOne/FilterBlock/FilterBlock"
import TitleBlock from "../components/TitleBlock/TitleBlock"
import FaqList from "../components/FaqList/FaqList"
import FaqCareerList from "../components/FaqCareersList/FaqCareerList.js";
import PropertyOwner from "../components/StaticTwo/PropertyOwner/PropertyOwner"
import GetStarted from "../components/GetStarted/GetStarted"
import BottomBlock from "../components/BottomBlock/BottomBlock"
import IntroBlock from "../components/IntroCopyBlock/IntroBlock";
import Communities from "../components/Communities/Communities"


import TenantCalculator from "../components/Calculator/TenantCalculator"
import MortgageCalculator from "../components/Calculator/MortgageCalculator"
import QuickKeyInvestment from "../components/Calculator/QuickKeyInvestment"
import RentVsBuyCalculator from "../components/Calculator/RentVsBuyCalculator"

import HTMLReactParser from 'html-react-parser';
import Accordion from "react-bootstrap/Accordion";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import { useContext } from "react"
import AccordionContext from "react-bootstrap/AccordionContext"

import Valuation from "../components/Valuation/Valuation"
import ThankyouBlock from "../components/ThankyouBlock/ThankyouBlock"

import Modal from "react-bootstrap/Modal"
import CareerForm from "../components/forms/career-form"
import SnaggingForm from "../components/forms/snagging-form"
import SpeakAdvisorForm from "../components/forms/speak-with-advisor"
import GetFreeQuoteForm from "../components/forms/get-free-quote"
import BannerEnquireForm from "../components/forms/banner_enquire_form"

import Review_Page from "../components/Review/Review_Page"

import PropertyToolkit from "../components/Valuation/PropertyToolkit/PropertyToolkit"
import GetStarted_Multifamliy from "../components/GetStarted/GetStarted_Multifamliy"

import AudioModule from "../modules/audio_module"
import HelpModule from "../modules/help_module"

import PlayVideo from "../components/Play/PlayVideo";
import wordsToNumbers from 'words-to-numbers';
import ReactPlayer from 'react-player'
import { removeDataAttributes } from "../comQueryStructure.js";
import { google_review_url } from "../components/common/utils.js";

function CommonTemplate(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isPlay, setPlay] = useState(false);
  const [isPlay2, setPlay2] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [modalHeading, setModalHeading] = useState('');
  const [modalType, setModalType] = useState('');

  const find_more_ref = createRef();

  const [testimonials, setTestimonials] = useState([]);
  const [reviewsCount, setReviewsCount] = useState(0);
  const [ratingAvg, setRatingAvg] = useState(0);

  const [renderComponent, setRenderComponent] = useState(false);

  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const data =
    props?.location?.pathname === "/market-research" ||
      props?.location?.pathname === "/market-research/"
      ? { category: "Research" }
      : null
  const sendSubmit = () => {
    if (data) {
      navigate("/about/case-studies-and-insights/", { state: data })
    }
  };

  useEffect(() => {
  	sendSubmit();
    let url = google_review_url
    getitems(url);

    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
  }, []);


  var ggl_reviews = [];
  var rating_count = 0;
  const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files
      const reviewsData = (data)
      if (reviewsData && reviewsData.length > 0) {
        //console.log("data",data)
        reviewsData.map((item, index) => { 
          rating_count += parseInt(wordsToNumbers(item.starRating));

          // if (star_no > 3) {
          //   if (item.comment) {
              ggl_reviews.push(item);
          //   }
          // }
          //console.log("ggl_reviews",ggl_reviews)
        })
        var rating_avg = (rating_count / reviewsData.length);
      }
      setTestimonials(ggl_reviews);
      setReviewsCount(reviewsData.length);
      setRatingAvg(rating_avg)

    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }

  const openModalForm = (e, modal_heading, modal_type) => {
    e.preventDefault();

    //console.log("modal_heading", modal_heading, modal_type);
    setIsOpen(true);
    setModalHeading(modal_heading);
    setModalType(modal_type);
  }

  const openVideoModal = (video_id) => {
    setVideoId(video_id);
    setPlay(true)
  }

  // const openVideoModal2 = (video_id) => {
  //   setVideoId(video_id);
  //   setPlay2(true)
  // }

  const findoutMore = () => {
    find_more_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }


   const type = typeof window!=="undefined"?window?.history?.state:""


  var GQLPage = props.GQLPage;
  var GQLModules = props.GQLModules;
  var GlobalModules = props.GlobalModules;
  var BannerImage = GQLPage && GQLPage.Header_Banner_Image;
  var BannerVideo = GQLPage && GQLPage.Header_Banner_Video;

  let processedImages = JSON.stringify({});
  if (GQLPage?.imagetransforms && GQLPage?.imagetransforms.Header_Banner_Image_Transforms) {
    processedImages = GQLPage?.imagetransforms.Header_Banner_Image_Transforms;
  }


  var main_cnt_cls = (GQLPage?.Layout !== "Default" && GQLPage?.Layout !== "Buy_Landing_Page") ? "page-content" : '';
  main_cnt_cls += (GQLPage?.Layout === "Content_Full_Width" || GQLPage?.Layout === "Rightside_Block" || GQLPage?.Layout === "Rightside_Block_No_Header") ? " static-page" : '';

  var middle_cls = (GQLPage?.Layout !== "Default" && GQLPage?.Layout !== "Buy_Landing_Page") && "middle_cnt";

  if(GQLPage?.Layout === "Rightside_Block_No_Header") {
     middle_cls += GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModRightsideModule").length > 0 ? ' col-xl-7' : '';
  }else{
    middle_cls += GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModRightsideModule").length > 0 ? ' col-xl-8' : '';
  }

  middle_cls += GQLPage?.Layout === "Content_Full_Width" ? ' col-xl-12' : '';

  middle_cls += GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModIntro").length > 0 ? ' pb-0' : '';

  // function ContextAwareToggle({ children, eventKey, callback }) {
  //   const currentEventKey = useContext(AccordionContext);

  //   const decoratedOnClick = useAccordionToggle(
  //     eventKey,
  //     () => callback && callback(eventKey),
  //   );

  //   const isCurrentEventKey = currentEventKey === eventKey;
  //   return (
  //     <button
  //       type="button"
  //       className={isCurrentEventKey ? 'active_card inactive_card' : 'inactive_card'}
  //       onClick={decoratedOnClick}
  //     >
  //       {children}
  //       <i
  //         className={isCurrentEventKey ? 'icon-minus-accordion ' : 'icon-plus-accordion'}
  //       ></i>
  //     </button>
  //   );
  // } 


  return (
    <>
      {
        props.GQLPage && (
          <Layout Layout={GQLPage.Layout} classNames={GQLPage.Layout} popular_search={GQLPage.popular_search} Alias={GQLPage.Alias} Select_Popular_Search={GQLPage.Select_Popular_Search} Pagename={GQLPage.Pagename}>

            <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} />

            <Helmet bodyAttributes={{ class: `homepage ${GQLPage ? GQLPage.Layout : ''} ${GQLPage ? GQLPage.Custom_CSS_Class : ''}` }} />

            {/* ====== Filter Header Banner ===== */}

            {
              GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModHeaderBanner" || "GLSTRAPI_ComponentComMultifamilyHeader").map((Modules, i) => {

                var image_name = (GQLPage.Layout === "Content_Full_Width" || GQLPage.Layout === "Rightside_Block" || GQLPage.Layout === "Rightside_Block_No_Header") ? 'article.Header_Banner_Image.inner_header' : 'article.Header_Banner_Image.header';

                return (
                  <>
                    {/* ======= Header Animate Banner Modules ====== */}
                    {BannerImage && Modules.Header_Title && 
                    <Banner Banner_Title={Modules.Header_Title && Modules.Header_Title} CTA_1_Label={Modules.CTA_1_Label && Modules.CTA_1_Label} 
                      CTA_1_URL={Modules.CTA_1_URL && Modules.CTA_1_URL} 
                      CTA_2_Label={Modules.CTA_2_Label && Modules.CTA_2_Label} 
                      CTA_2_URL={Modules.CTA_2_URL && Modules.CTA_2_URL}
                       Banner_Image={BannerImage} 
                       Banner_Video={BannerVideo} 
                       Content={Modules.Header_Content ? Modules.Header_Content : Modules.Content} 
                       Layout={GQLPage.Layout} 
                       openModalForm={openModalForm} 
                       imagename={image_name} 
                       article_id={GQLPage.id} 
                       imagetransforms={GQLPage.ggfx_results} 
                       handleClick={findoutMore} 
                       GQLModules={GQLModules} 
                       GQLPage={GQLPage} Show_Banner_Google_Review={Modules.Show_Banner_Google_Review} 
                       testimonials={testimonials} 
                       totalReviewsCount={reviewsCount} 
                       ratingAvg={ratingAvg} Alias={GQLPage.Alias} 
                       Show_Enquire_Form={Modules.Show_Enquire_Form} 
                       Form_Heading={Modules.Form_Heading} 
                       Play_Button={GQLPage.Play_Button} 
                       location={props.location} 
                       description={GQLPage.Meta_Description} template={"common"} />
                    }
                  </>
                )
              })
            }            
            {GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModProjectFacts").map((Modules, i) => {
                    return (
                         <>
                         <div className="PropertyFactsList-RegisterForm" id="PropertyFactsList-RegisterForm">
                          <Container>
                              <h3>Register Your Interest Now</h3>
                              <RegisterForm />
                          </Container>
                         </div>
                        <PropertyFactsList data={Modules} />
                        </>
                    )
               })
            }

            {GQLPage.Alias === "reviews" && <Review_Page Modules={GQLModules} testimonials={testimonials} />}



            {
              GQLPage.Layout === "Calculator" ? (
                <>
                  {/* { GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModAudioModule").map((Modules, i) => {
                  return(
                      Modules.Embed_Video_Link && <HelpModule Embed_Video_Link={Modules.Embed_Video_Link} />
                  )
              })} */}

                  {GQLPage.Alias === "Tenant Calculator" && <TenantCalculator page={GQLPage} modules={GQLModules} openModalForm={openModalForm} />}

                  {GQLPage.Alias === "Mortgage Calculator" && <MortgageCalculator page={GQLPage} modules={GQLModules} openModalForm={openModalForm} />}

                  {GQLPage.Alias === "Quick Key Investment Metrics" && <QuickKeyInvestment page={GQLPage} modules={GQLModules} openModalForm={openModalForm} />}

                  {GQLPage.Alias === "Rent vs Buy Analysis" && <RentVsBuyCalculator page={GQLPage} modules={GQLModules} openModalForm={openModalForm} location={props.location} template={"common"} />}
                </>
              ) : (

                GQLPage.Alias === "valuation" ? (
                  <Valuation Valuation_data={GQLModules} />
                ) : (
                  <div className={`${main_cnt_cls} ${GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ''}`} ref={find_more_ref}>
                    <Container>
                      <Row>
                        <div className={middle_cls}>
                          {/* ======= Breadcrumbs ====== */}

                          {GQLPage.Layout === "Rightside_Block_No_Header" && 
                               GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModProjectFacts").map((Modules, i) => {
                                  return (                                       
                                      <ProjectFacts data={Modules}/>                           
                                  )           
                               })
                          }
                          

                          {(GQLPage.Layout === "Without_Banner" && GQLPage.Alias !== "valuation" && GQLPage.Alias !== "valuation-confirmation" && GQLPage.Alias !== "pre-qualified-confirmation")
                            ?
                            <>
                              <Breadcrumbs />
                              {
                                // GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename !== "GLSTRAPI_ComponentModCollections") && GQLPage.Pagename && GQLPage.Content && <TitleBlock Title={GQLPage.Pagename} Alias={GQLPage.Alias} Description={GQLPage.Content} />

                                GQLPage.Alias !== "new-projects" && GQLPage.Alias !== "new-projects-rent" && GQLPage.Alias !== "new-projects-rent-in-dubai" && GQLPage.Alias !== "our-new-projects-in-dubai" && GQLPage.Pagename && GQLPage.Content && 
                                <TitleBlock Title={GQLPage.Pagename} Alias={GQLPage.Alias} Description={GQLPage.Content} />

                              }

                            </>
                            :
                            GQLPage.Alias != "contact-us" && GQLPage.Content && <ContentBlock Content={GQLPage.Content} Content_Heading_Text={GQLPage.Content_Heading_Text} classNames={GQLPage.Layout} page={GQLPage} />
                          }



                          {/* ====== Main Container Modules ===== */}
                          {GQLModules.map((Modules, i) => {

                            //console.log('Modules', Modules);
                            return (
                              <>
                                {/* ======= Inner Page Content Block ====== */}
                                {GQLPage.Alias !== "careers" && GQLPage.Alias !== "reviews" && Modules.__typename === "GLSTRAPI_ComponentModContentBlock" &&
                                  <ContentBlock Content={Modules.Content} Content_Heading_Text={GQLPage.Content_Heading_Text} classNames={GQLPage.Layout} page={GQLPage} />
                                }
                                {/* ======= Inner Page Step Content Block ====== */}
                                { GQLPage.Alias !== "property-management-packages" && Modules.__typename === "GLSTRAPI_ComponentComStepContentBlock" && Modules.Steps &&
                                  <StepContentBlock StepContent={Modules.Steps} />
                                }

                                {/* ======= Accordion Block ====== */}
                                {
                                    Modules.Add_Accor_Item && Modules.Add_Accor_Item.length > 0 &&
                                      <AccordionBlock AccordionItems={Modules.Add_Accor_Item} ViewMore={false} classNames={GQLPage.Layout} />
                                  }

                                {
                                  Modules.Tableu_Accordian && Modules.Tableu_Accordian.length > 0 && Modules.__typename === "GLSTRAPI_ComponentModTableuAccordianBlock" &&
                                  <TableuAccordionBlock AccordionItems={Modules.Tableu_Accordian} ViewMore={false} classNames={GQLPage.Layout} activeId={"0"} location={props.location} />
                                }

                                {/* {JSON.stringify(Modules.Tableu_Accordian)} */}

                                {/* ======= Insights Page ====== */}
                                {Modules.Select_Collection === "Insights" && <Insights location={props.location} categoryTag={type?.slug} />}

                                 {/* ======= Market Statistics ====== */}
                                {Modules.Select_Collection === "Market_Statistics" && <MarketStatistics />}
                                
                                {/* ======= New Projects Page ====== */}
                                {Modules.Select_Collection === "New_Projects" && <NewProjects Title={GQLPage.Pagename} Alias={GQLPage.Alias} pstatus={GQLPage.Alias === "new-projects" ? "Sale" : "Rent"} Description={GQLPage.Content} location={props.location} region={"Qatar"}  Meta_Description={GQLPage.Meta_Description} Meta_Title={GQLPage.Meta_Title} />}

                                {/* ======= New Projects Dubai Page ====== */}
                                {Modules.Select_Collection === "New_Projects_Dubai" && <NewProjects Title={GQLPage.Pagename} Alias={GQLPage.Alias} pstatus={GQLPage.Alias === "our-new-projects-in-dubai" ? "Sale" : "Rent"} Description={GQLPage.Content} location={props.location} region={"Dubai"}   Meta_Description={GQLPage.Meta_Description} Meta_Title={GQLPage.Meta_Title} />}


                                {/* ======= Our Peoples Page ====== */}
                                {Modules.Select_Collection === "Peoples" && <OurPeoples location={props.location} />}

                                {/* ======= Our Communities Page ====== */}
                                {Modules.Show_Community_List && <Communities location={props.location} />}



                                {/* ======= Valuation Confirmation ====== */}
                                {
                                  Modules.__typename === "GLSTRAPI_ComponentModValuationThankyou" &&
                                  <ThankyouBlock Pagename={GQLPage.Pagename} Modules={Modules} Alias={GQLPage.Alias} />
                                }

                                {
                                  Modules.__typename === "GLSTRAPI_ComponentComToolkit" &&
                                  <PropertyToolkit Toolkit={Modules} />
                                }

                                {
                                  Modules.__typename === "GLSTRAPI_ComponentModValautionAccordianBlock" &&

                                  <ValuationAccordionBlock Accordian_Title={Modules.Title} AccordionItems={Modules.Accordian_Block} ViewMore={true} classNames={GQLPage.Layout} ViewMoreLabel={Modules.CTA_1_Label} ViewMoreLink={Modules.CTA_1_URL} />
                                }

                              </>
                            )
                          })}

                          {/* ======= FAQ and Tips Page ====== */}
                          {(GQLPage.Alias === "faq" || GQLPage.Alias === "tips") &&
                            <FaqList FaqItems={GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModFaqBloack")} />}
                        </div>


                        {/* ====== Rightside Modules ===== */}
                        {
                          GQLPage.Layout != "Calculator" &&
                          GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModRightsideModule").map((Modules, i) => {
                          
                            return (
                              <>
                                {Modules.modules && Modules.modules?.length > 0 &&
                                  <div className="col-xl-4 right_side"><div className="member-card-block">
                                    <MemberCard  Layout={GQLPage.Layout}  RightSideModule={Modules.modules[0].ModuleType[0]} openModalForm={openModalForm} Pagename={GQLPage.Pagename} MetaTitle={GQLPage.Meta_Title} WhatsappText={GQLPage.Pagename} page={GQLPage} location={props.location} template={"common"}/> </div></div>}
                              </>
                            )
                          })
                        }
                      </Row>
                    </Container>

                    {/* ====== Main Container Modules ===== */}
                    {GQLModules.map((Modules, i) => {
                      return (
                        <>
                          {(GQLPage.Layout !== "Default" && GQLPage.Layout !== "Buy_Landing_Page") && Modules.Show_Insights && <FilterBlock Filter_by_Tag={Modules.Filter_by_Tag ? Modules.Filter_by_Tag : ''} />}

                          {/* ======= Intro Section ====== */}
                          {Modules.Intro_Title && Modules.Intro_Text &&
                            <ContactInfo Title={Modules.Intro_Title} Content={Modules.Intro_Text} Office_Details={Modules.Choose_Office && Modules.Choose_Office} AccordionItems={Modules.Add_Accor_Item && Modules.Add_Accor_Item.length > 0 && Modules.Add_Accor_Item} Accordian_Title={Modules.Intro_Accordian_Title} Accordian_List={Modules.Intro_Accordian_List} Services_Title={Modules.Intro_Services_Title} Services_List={Modules.Intro_Services_List} Service_Text={Modules.Service_Text} Service_Icon={Modules.Service_Icon} />
                          }

                          {Modules.__typename === "GLSTRAPI_ComponentComGridBlock" &&
                            <GridBlock {...Modules} />
                          }

                          { GQLPage.Alias == "property-management-packages" && Modules.__typename === "GLSTRAPI_ComponentComStepContentBlock" && Modules.Steps &&
                            <StepContentBlock StepContent={Modules.Steps} />
                          }

                          {/* ======= Timeline Page ====== */}
                          {Modules.Show_Timeline && <ProjectTimeline />}
 						  {Modules.__typename === "GLSTRAPI_ComponentModFaqBloack" && (GQLPage.Alias === "careers") &&
                            <FaqCareerList FaqItems={GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModFaqBloack")} />
                          }
                          {/* ======= Contact Us Page ====== */}
                          {Modules.__typename === "GLSTRAPI_ComponentModChooseOffice" &&
                            <ContactUs Content={GQLPage.Content} GQLPage={GQLPage} Modules={Modules} testimonials={testimonials} />}

                          {/* ======= Tiles  Section ====== */}
                          {Modules.Add_Item && Modules.Add_Item.length > 0 &&
                            <TilesBlock Tile_Blocks={Modules.Add_Item} />
                          }

                          {/* ======= Graph Analytics ====== */}
                          {
                            Modules.Add_Tabs != null && Modules.Add_Tabs.length > 0 && Modules.Add_Tabs &&
                            <MarketAnalysis Tab_Data={Modules.Add_Tabs} Alias={GQLPage.Alias} />

                          }

                          {/* ======= Snaging Tiles Block Section ====== */}
                          {Modules.Snagging_Tiles && Modules.Snagging_Tiles.length > 0 &&
                            <PropertyOwner Tiles_Blocks={Modules.Snagging_Tiles} openModalForm={openModalForm} />
                          }

                          {/* ======= Get Started Block Section ====== */}
                          {Modules.__typename === "GLSTRAPI_ComponentModGetStarted" &&
                            <GetStarted Get_Started_Block={Modules} pageAlias={GQLPage.Alias} />
                          }

                          {/* ======= Full Width Video ====== */}
                          {Modules.Embed_Video_URL != null && Modules.Background_Image &&
                            <MarketVideo Embed_Video_URL={Modules.Embed_Video_URL} Background_Image={Modules.Background_Image} Video_Label={Modules.Video_Label && Modules.Video_Label} mute={false} />
                          }

                          {/* ======= Get Started Block Section ====== */}
                          {/* {Modules.__typename === "GLSTRAPI_ComponentComMultifamilyTableauBlock" &&
                            <GetStarted_Multifamliy Get_Started_Block={Modules} />
                          } */}



                          {/* ======= Jobs Page ====== */}
                          {Modules.Select_Collection === "Jobs" &&
                            <Jobs openModalForm={openModalForm} Modules={GQLModules} />}

                          {/* ======= Videos Page ====== */}
                          {Modules.__typename === "GLSTRAPI_ComponentComPlaylistIncludeVideo" && <Videos Modules={Modules} GQLModules={GQLModules} />}

                          {/* ======= Bottom Content Block ====== */}
                          {Modules.__typename === "GLSTRAPI_ComponentModBottomBlock" &&
                            <BottomBlock Bottom_Block={Modules} openModalForm={openModalForm} />}

                          {/* ======= Intro Content Block ====== */}
                          {Modules.__typename === "GLSTRAPI_ComponentModIntroCopyBlock" &&
                             <IntroBlock IntroTitle={Modules?.Intro_Copy_Title} IntroPara={Modules?.Intro_Copy_Content}/>
                          }
                          
                          {/* ======= Featured Properties ====== */}
                          {Modules.Show_Property && <ProjectRating Title={Modules.Title} Alias={GQLPage.Alias} StbList={Modules.Stb_List && Modules.Stb_List} />}

                          {Modules.__typename === "GLSTRAPI_ComponentModProjectFacts" && Modules.Upload_Images &&
                             <ProjectFactsSlider Upload_Images={Modules.Upload_Images}  />
                          }

                          {Modules.__typename === "GLSTRAPI_ComponentModProjectFacts" && Modules.Latitude && Modules.Longitude &&
                             <ProjectFactsMaps Latitude={Modules.Latitude} Longitude={Modules.Longitude}  />
                          }
                         
                          {/* ======= Lastest Blogs Insights-Case Studies ====== */}
                          {(GQLPage.Layout === "Default" || GQLPage.Layout === "Buy_Landing_Page") && Modules.Show_Insights &&
                            <div className="d-md-flex flex-wrap">
                              <LatestBlogs Insights_Block={GlobalModules?.Insights_Block} Filter_by_Tag={Modules?.Filter_by_Tag} /> </div>}

                          {/* ======= Google Reviews ====== */}
                          {renderComponent && Modules.Show_Google_Review && <div className="d-md-flex flex-wrap"> <Reviews testimonials={testimonials} /> </div>}
                          {Modules.__typename =="GLSTRAPI_ComponentModBlogCategoriesList" && GQLPage.Alias == "our-communities" &&
                            <div className="">
                              <LatestBlogsbyTag Filter_by_Tag={["General-Blogs", "Lusail City"]} />
                            </div>
                          }
                          {Modules.__typename =="GLSTRAPI_ComponentModBlogCategoriesList" && 
                          GQLPage.Alias != "our-communities" && 
                            <div className="">
                              <LatestBlogsbyCategory Filter_by_Tag={Modules} /> 
                            </div>
                          }
                        </>
                      )
                    })}

                    <Modal
                      id="valuation_modal"
                      show={isOpen}
                      onHide={() => setIsOpen(false)}
                      backdrop="static"
                      keyboard={false}
                      className="member-contact-modal"
                    >
                      {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}


                      {
                        (modalType === "multifamily_enquiry_form") ?
                          <>
                            <Modal.Header closeButton className="contact-close-btn memberPopup">
                              <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">{modalHeading}<br /><span>{GQLPage.Pagename}</span></h3></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <BannerEnquireForm formName={GQLPage.Pagename + '-' + modalHeading} formType={modalType} formLabel={modalHeading} />
                            </Modal.Body>
                          </>
                          :
                          (modalType === "get_free_quote") ?
                            <>
                              <Modal.Header closeButton className="contact-close-btn memberPopup">
                                <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">{modalHeading}<br /><span>{GQLPage.Pagename}</span></h3></Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <GetFreeQuoteForm formName={GQLPage.Pagename + '-' + modalHeading} formType={modalType} formLabel={modalHeading} />
                              </Modal.Body>
                            </>
                            :
                            (modalType === "mortgage_advisor" || modalType === "moving_home") ?
                              <>
                                <Modal.Header closeButton className="contact-close-btn memberPopup">
                                  <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">{modalHeading}<br /><span>{GQLPage.Pagename}</span></h3></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <SpeakAdvisorForm formName={GQLPage.Pagename + '-' + modalHeading} formType={modalType} formLabel={modalHeading} subjectTitle={modalHeading + '-' +GQLPage.Pagename}/>
                                </Modal.Body>
                              </>
                              :
                              GQLPage.Alias === "snagging-and-handover-inspections" ? (
                                <>
                                  <Modal.Header closeButton className="contact-close-btn memberPopup">
                                    <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">{modalHeading}</h3></Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <SnaggingForm form_name={modalHeading} form_type={modalHeading && modalHeading.replace('Snagging & Handover - ', '').toLowerCase().trimStart()} formLabel={modalHeading} />
                                  </Modal.Body>
                                </>
                              ) : (
                                <>
                                  <Modal.Header closeButton className="contact-close-btn memberPopup">
                                    <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">Send us Your CV</h3></Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <CareerForm formName={`Job Application`} formLabel={`Job Application`} jobtitle="" />
                                  </Modal.Body>
                                </>
                              )
                      }
                    </Modal>

                    <PlayVideo
                      isOpen={isPlay}
                      isCloseFunction={setPlay}
                      videoId={videoId}
                      isAutoPlay={1}
                    />

                    {/* <div className={`rght_vid ${isPlay2 ? 'btmt' : ''}`}> { isPlay2 && <><a href="javascript:;" onClick={()=>setPlay2(false)} className="clse_btn">X</a> <ReactPlayer url={videoId} modestbranding="1" controls={false} autoplay={true} muted={true} playsinline={true} loop={true} playing={true} width='100%' height='100%'></ReactPlayer></>}</div> */}


                  </div>
                )


              )


            }

            {/* { GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModAudioModule").map((Modules, i) => {
              return(
                  Modules.Audio_File && Modules.Audio_File.url && <AudioModule Modules={Modules} />
              )
          })} */}

          </Layout>

        )
      }
    </>
  )
}

export default CommonTemplate
