import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import dateFormat from 'dateformat';
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

import ContactCard from "../ContactCard/ContactCard";
import { Insights, MarketStatistic } from "../../queries/common_use_query";
import bgCurve2 from "../../images/home/pg-logo-curve-2.webp";
import "./FilterBlock.scss";
const MarketStatistics = (props) => {

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  const { loading, error, data } = MarketStatistic();
  const initInsightsData = data?.marketStatistics?.data
  //console.log("datadata", initInsightsData)

  const contactCard = (item) => {

    //const card_imgs = item?.attributes?.ggfx_results?.data?.[0]?.attributes?.transforms?.map(transform => transform.url) || 
    //item?.ggfx_results?.[0]?.transforms?.map(transform => transform.url);

    const cardImageUrl = item?.attributes?.Title_images?.data?.attributes?.url?item?.attributes?.Title_images?.data?.attributes?.url:item.Tile_Image.url
    const cardImg = { url: cardImageUrl };
    const imageClsName = item?.attributes?.image_class ? item.attributes.image_class : item?.image_class

    return (
      <ContactCard
      key={item.id}
      cardImg = {cardImg}
      cardTitle={item?.attributes?.Title ? item.attributes.Title : item?.Title}
      link_url={item?.attributes?.URL ? item.attributes.URL : item?.URL}
      page="insights"
      PageURL = "MarketStatistics"
      imagename={imageClsName?"insight.Tile_Image.tile_image_list":"insight.Tile_Image.tile_image"}
      article_id={item?.id}
      imageClsName={imageClsName?imageClsName:''}
      imagetransforms={item?.attributes?.ggfx_results ? item.attributes.ggfx_results : item?.ggfx_results}
      externalLink={item?.attributes?.External_Link ? item.attributes.External_Link : item?.External_Link}
      />
    );
  };




  return (
    <div className="filter-block insights video_filter">
      <span className="bg-curve"><img src={bgCurve2} alt="bg-img" /></span>
      <Container>
        <Row>
          <Col lg="12">
            <div className="animated">
              <div className={`filter-block-list case_insights text-center text-xl-left`}>
                {initInsightsData?.length >0 && initInsightsData.map(item => contactCard(item))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MarketStatistics
