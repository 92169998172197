import React,{useEffect} from "react"
import {Container, Row, Col, Badge, Nav,Card} from 'react-bootstrap';
import $ from 'jquery';
import ScrollAnimation from 'react-animate-on-scroll';
import "./PropertyFacts.scss"

// styles

// markup
const ProjectFacts = ( props ) => { 


  useEffect(() => {
    $(document).ready(function () {
      $(".tab-switch ul li a").click(function (e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html, body').animate({
          scrollTop: $(aid).offset().top - ($(".header").outerHeight() + $(".tab-switch").outerHeight() + 50)
        }, 1000);
        $(this).parent().addClass('active').siblings().removeClass('active');


      });

      $(window).on('scroll', function () {

        //console.log("main-banner-embed", $(".main-banner-embed")[0]?.getBoundingClientRect()?.bottom)
       // console.log("PropertyFactsList-RegisterForm", $(".PropertyFactsList-RegisterForm")[0]?.getBoundingClientRect()?.bottom)

          if ($(window).width() > 992) {
            if ($(".main-banner-embed")[0]?.getBoundingClientRect()?.bottom < 0) {
              $('.tab-switch').addClass('stick');
            } else {
              $('.tab-switch').removeClass('stick');
            }
          }else{
            if ($(".PropertyFactsList-RegisterForm")[0]?.getBoundingClientRect()?.bottom < 0) {
              $('.tab-switch').addClass('stick');
            } else {
              $('.tab-switch').removeClass('stick');
            }
          }
      });
    });
  }, []);

  return (
  <React.Fragment>
    <>
    <Nav className="tab-switch secondary-nav">
          <Container>
            <Row>
              <ul className="d-flex col-lg-12">
                {props?.data.facts_content &&
                <li class="active"><a href="#project_facts">Project Facts</a></li>
                }
                {props?.data.Key_Features &&
                <li><a href="#details_key_features">Details & Key Features</a></li>
                }
                {props?.data.Development_Strategy &&
                <li><a href="#development_strategy">Development & Strategy</a></li>
                }
                {props?.data.Payment_Plan_Modules &&
                <li><a href="#our_payment_plan">Our Payment Plan</a></li>
                }
                {props?.data.floor_plans &&
                <li><a href="#floorplans">Floorplans</a></li>
                }               
              </ul>
            </Row>
          </Container>
        </Nav>
   </>
  </React.Fragment>
  )
}

export default ProjectFacts